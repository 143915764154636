import { useEffect } from 'react';
import io from 'socket.io-client';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import HelmetComponent from './components/Helmet';
// eslint-disable-next-line import/no-cycle
import Router from './router/Router';
import DefaultTheme from './styles/DefaultTheme';

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;
}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: black;
  text-decoration: none;
  
}
* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  font-family: 'Noto Sans KR', sans-serif;
}
`;

// export const socket = io.connect('https://api.k-doll-ai-restful.com/realchat', {
//   transports: ['websocket'],
// });
export const socket = io.connect('http://125.6.39.158:5000/realchat', {
    transports: ['websocket'],
});

socket.emit('join', { serial_number: 'abcd1234', type: 'SUPERVISOR' });

function App() {
  useEffect(
    () => () => {
      socket.close();
    },
    [],
  );
  return (
    <ThemeProvider theme={DefaultTheme}>
      <HelmetComponent />
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
}

export default App;
