const colors = {
  pink: "#FFC6AE",
  darkPink: "#fea47f",
};

const fontSizes = {
  nav: "1.8rem",
  main: "1.4rem",
};

const fontWeights = {
  nav: "500",
};

const size = {
  small: `(max-width: 480px)`,
};

const DefaultTheme = {
  size,
  colors,
  fontSizes,
  fontWeights,
};

export default DefaultTheme;
